@use '../../styleguide/colors.scss';
@use '../../styleguide/vars.scss';

.searchBar {
  background-color: colors.$white;
  border-radius: 4px;
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  position: relative;
  width: 100%;
  padding: 4px;
  contain: content;

  &Unset {
    contain: unset;
  }

  @media screen and (min-width: vars.$medium) {
    padding: 4px;
  }

  @media screen and (min-width: vars.$large) {
    margin: 0;
    width: 100%;
  }

  @media screen and (min-width: vars.$extra-large) {
    padding: 0;
  }
}

.searchPlaceholder {
  overflow: hidden;
  width: 100%;

  @media screen and (min-width: vars.$extra-large) {
    position: relative;
  }

  &Hidden {
    display: none;
  }
}

.placeholderText {
  color: colors.$dark-text;
  overflow: hidden;
  padding-left: 12px;
  padding-right: 60px;
  pointer-events: none;
  position: absolute;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;

  @media screen and (min-width: vars.$medium) {
    padding-right: 230px;
  }

  @media screen and (min-width: vars.$large) {
    padding-right: 240px;
  }

  @media screen and (min-width: vars.$extra-large) {
    padding-left: 0;
    padding-right: 370px;
    width: auto;
  }

  @media screen and (min-width: vars.$extra-large) {
    padding-right: 0px;
    width: 95%;
  }
}

.searchInput {
  border: none;
  width: 100%;
  padding-left: 12px;

  @media screen and (min-width: vars.$extra-large) {
    padding-left: 0;
  }
}

.searchInput:focus {
  outline: 0;
}

.searchInput::-ms-clear {
  display: none;
}

.inputContainer {
  align-items: center;
  position: relative;
  display: flex;
  flex-direction: row;

  @media screen and (min-width: vars.$extra-large) {
    height: 56px;
  }
}

.searchIcon {
  display: none;
  color: colors.$dark-grey;
  font-size: 1.25em;

  @media screen and (min-width: vars.$extra-large) {
    display: flex;
    margin: 0 6px 0 14px;
    svg {
      height: 20px;
      width: 20px;
    }
  }
}

.searchIcons {
  align-items: center;
  justify-content: flex-end;
  color: colors.$white;
  display: flex;
}

.clearIcon {
  flex: 0 0 auto;
  cursor: pointer;
  background-color: colors.$grey;
  height: 20px;
  width: 20px;
  font-size: 26px;
  line-height: 19px;
  text-align: center;
  border-radius: 50%;
  margin-right: 16px;
}

.searchIcons svg {
  display: block;
}

// This is needed to make this specific 'search' icon white
.searchIcons svg g g {
  fill: #fff;
}

.searchButton {
  background-color: colors.$secondary-color;
  border: none;
  border-radius: 4px;
  color: colors.$white;
  cursor: pointer;
  font-size: 1rem;
  margin-left: 10px;
  outline: none;
  padding: 13px 16px;
  position: relative;
  width: 48px;
  height: 43px;

  @media screen and (min-width: vars.$extra-large) {
    margin-right: 4px;
  }

  @media screen and (min-width: vars.$extra-extra-large) {
    height: 48px;
  }
}

.SearchButtonMP {
  @media screen and (min-width: vars.$extra-large) {
      display: none;
  }
}

.searchIcons > svg:first-child {
  background-color: colors.$grey;
  border-radius: 50%;
  cursor: pointer;
  padding: 2px;
}

// .searchIcons svg:last-child:hover {
//   background-color: $secondary-color-dark;
// }

.categoryPicker {
  display: none;
  color: colors.$default-text;

  @media screen and (min-width: vars.$extra-large) {
    display: flex;
    padding: 15px;
    border-right: 1px solid colors.$grey;
  }
}

.searchIconActionsMP {
  padding: 0 12px;
  align-items: center;
  gap: 16px;
  position: relative;
  display: none;
  cursor: pointer;

  &::before {
    content: '';
    width: 1px;
    position: absolute;
    left: 0;
    height: 56px;
    background-color: colors.$grey;
  }

  & svg {
    fill: none;
    &:not(:last-child) {
      cursor: pointer;
    }
  }

  @media screen and (min-width: vars.$extra-large) {
    display: flex;

    & + .searchButton {
      display: none;
    }
  }
}

.searchIconActionsClickout {
  display: flex;
  padding: 8px 12px;
  margin-left: 12px;
}